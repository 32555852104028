import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-09f703f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.img)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.imgUrl,
          alt: ""
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass(_ctx.icon),
          "aria-hidden": "true"
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.heading && _ctx.secondary)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.heading), 1))
      : (_ctx.heading && !_ctx.secondary)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.heading), 1))
        : _createCommentVNode("", true),
    _createElementVNode("p", { innerHTML: _ctx.text }, null, 8, _hoisted_4),
    _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
  ]))
}